import React, { useEffect } from 'react';
import './App.css';
import Lottie from 'react-lottie';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppStoreIcon from "./assets/images/AppStore.png";
import leftCarouselPhone from "./assets/images/app-splash.png";
import midCarouselPhone from "./assets/images/app-home-2x.png";
import rightCarouselPhone from "./assets/images/app-home.png";
import videoPlaceholder from "./assets/images/video-placeholder-2x.png";

import animationData from './assets/images/breathing-transparent-animation.json'


function App() {
  useEffect(() => {
    document.title = "The Breathing Room App"
  });

  const defaultPulsingAnimationOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const newsletterSubmitBtnRef = React.useRef(null)

  return (
    <div className='container-fluid'>

        <div className='row d-flex'>
          <div className='col-xl-6 col-lg-12 col-md-12 nopadding'>
            <div className='breathing-intro-section min-vh-100'>
              <h1 className='title'>The Breathing Room App</h1>
              <p className='description pt-4'>Top Section (Shots of the app with a frame of the app in an iPhone 14) link and icon to download on the app store</p>

              <div className='app-images-carousel mt-3'>
                <img src={leftCarouselPhone} className='left-or-right-img'/>
                <img src={midCarouselPhone} className='middle-img'/>
                <img src={rightCarouselPhone} className='left-or-right-img'/>
              </div>

              <div className='app-download-area mt-2'>
                <span className='title'>Download now on:</span>
                <div className='download-sources mt-3'>
                  <img src={AppStoreIcon} className='icon'/>
                </div>
              </div>

              <div className='privacy-middle-left'>
                Privacy Policy
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12 col-md-12 nopadding'>
            <div className='explaning-section min-vh-100'>
              <div className='content'>
                <h2 className='title'>Why the app was developed?</h2>

                <p className='description pt-4'>
                  Top Section (Shots of the app with a frame of the app in an iPhone 14) link and icon to download on the app store. 
                  Video that Tim will make talking about why the app was developed Join the Breathing Room newsletter section
                </p>

                <div className='video-box'>
                  <img src={videoPlaceholder} className='video'/>
                </div>

                <div className='newsletter p-4'>
                  <form method='POST' action='https://superiordigital.activehosted.com/proc.php'>
                    <input type="hidden" name="u" value="1" />
                    <input type="hidden" name="f" value="1" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />
                    <input type="hidden" name="or" value="8fd769206aa3543764a1b5fe24075110" />

                    <span className='title'>Join the breathing room newsletter</span>
                    <div className='inputs d-flex'>
                      <div className='email-bg'>
                        <input type='email' className='email-field' placeholder='Your email' name='email' id='email' required/>
                      </div>
                      
                      <button ref={newsletterSubmitBtnRef} type='submit' style={{display: 'none'}}>Join the newsletter</button> 
                      <div className='join-btn' onClick={() => {newsletterSubmitBtnRef.current.click()}}>
                        <span>Join the newsletter</span>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
              <br />
              <div className='privacy-middle-right'>
                2022 Superior Digital Partners, LLC
              </div>

              <Lottie 
                options={defaultPulsingAnimationOptions}
                isClickToPauseDisabled={true}
                style={{
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: -10,
                  marginTop: '0vh',
                  cursor: 'context-menu',
                }}/>
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;
